<template>
  <div class="signup-container">
    <el-form ref="signup" :model="form" label-width="86px">
      <el-form-item label="用户名">
        <el-input v-model="form.userName"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <template v-if="role === 'servicer'">
        <el-form-item label="展示名称">
          <el-input v-model="form.displayName"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="法定代表人">
          <el-input v-model="form.legalPerson"></el-input>
        </el-form-item>
        <el-form-item label="信用代码">
          <el-input v-model="form.creditCode"></el-input>
        </el-form-item>
        <el-form-item label="注册地址">
          <el-input v-model="form.registeredAddress"></el-input>
        </el-form-item>
        <el-form-item label="生产场地">
          <el-input v-model="form.productionSite"></el-input>
        </el-form-item>
      </template>
      <el-form-item label="联系方式">
        <el-input type="tel" v-model="form.contact"></el-input>
      </el-form-item>
      <el-form-item class="signup-btn">
        <el-button type="primary" @click="signup">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      role: "user",
    };
  },
  methods: {
    signup() {
      console.log(this.$refs);
      let model = JSON.parse(JSON.stringify(this.$refs["signup"].model));
      // console.log(this.$route.query.role, model);
      if (this.$route.query.role && this.$route.query.role === "user") {
        this.$axios.postCustomer(model).then((res) => {
          if (!res.error) {
            console.log(res);
            this.$router.push("/tips");
            // this.$notify({
            //   title: "提示",
            //   message: "注册成功等待管理员审核通过",
            //   duration: 2000,
            // });
          } else {
            this.$notify({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      } else if (
        this.$route.query.role &&
        this.$route.query.role === "servicer"
      ) {
        this.$axios.postServicer(model).then((res) => {
          if (!res.error) {
            console.log(res);
            this.$router.push("/tips");
            // this.$notify({
            //   title: "提示",
            //   message: "注册成功等待管理员审核通过",
            //   duration: 2000,
            // });
          } else {
            this.$notify({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
  },
  mounted() {
    if (this.$route.query.role) {
      this.role = this.$route.query.role;
    }
  },
};
</script>

<style lang="scss">
.signup-container {
  margin-top: 2rem;
  .signup-btn {
    text-align: center;
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
</style>
